import * as React from "react";
import { Link } from "react-router-dom";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ImageIcon from "@mui/icons-material/Image";
import { useEffect } from "react";
import { motion } from "framer-motion";
function ReportLandinPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="links-box">
      <div className="links-role-div">
        <h2 style={{ marginLeft: "50px" }}>Reports Section</h2>
        <div
          style={{
            borderRadius: "20px",
            display: "flex",
            backgroundColor: "#E5E4E2",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-around",
            marginTop: "10px",
            padding: "40px",
          }}
        >
          <Link to={`/reports/chart`} style={{ textDecoration: "none" }}>
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
            >
              <div className="link-box">
                <div className="link-box-icon">
                  <AnalyticsIcon sx={{ width: "60px", height: "90px" }} />
                </div>
                <div className="link-box-title">
                  <p>Graph Reports</p>
                </div>
              </div>
            </motion.div>
          </Link>

          <Link to={`/reports/panel`} style={{ textDecoration: "none" }}>
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
            >
              <div className="link-box">
                <div className="link-box-icon">
                  <ImageIcon sx={{ width: "60px", height: "90px" }} />
                </div>
                <div className="link-box-title">
                  <p>Role Mismatch Reports</p>
                </div>
              </div>
            </motion.div>
          </Link>

          <Link to={`/reports/utilization`} style={{ textDecoration: "none" }}>
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
            >
              <div className="link-box">
                <div className="link-box-icon">
                  <ImageIcon sx={{ width: "60px", height: "90px" }} />
                </div>
                <div className="link-box-title">
                  <p>Panel Utilization Report</p>
                </div>
              </div>
            </motion.div>
          </Link>

          <Link
            to={`/reports/recruiterutilization`}
            style={{ textDecoration: "none" }}
          >
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
            >
              <div className="link-box">
                <div className="link-box-icon">
                  <ImageIcon sx={{ width: "60px", height: "90px" }} />
                </div>
                <div className="link-box-title">
                  <p>Recruiter Utilization Report</p>
                </div>
              </div>
            </motion.div>
          </Link>

          <Link
            to={`/reports/domains`}
            style={{ textDecoration: "none" }}
          >
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
            >
              <div className="link-box">
                <div className="link-box-icon">
                  <ImageIcon sx={{ width: "60px", height: "90px" }} />
                </div>
                <div className="link-box-title">
                  <p>Domain Report</p>
                </div>
              </div>
            </motion.div>
          </Link>

          <Link to={`/reports/panelreport`} style={{ textDecoration: "none" }}>
            <motion.div
              whileHover={{
                scale: 1.2,
              }}
            >
              <div className="link-box">
                <div className="link-box-icon">
                  <ImageIcon sx={{ width: "60px", height: "90px" }} />
                </div>
                <div className="link-box-title">
                  <p>Full Panel Report</p>
                </div>
              </div>
            </motion.div>
          </Link>
        </div>
      </div>
    </div>
  );
}
export default ReportLandinPage;
