import * as React from "react";
import { Loader, useAuthenticator } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { proxy } from "../proxy";
import axios from "axios";
import { useEffect } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import "../styles/CandidateProfile.css";
import "./charts/Reports.css";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import WorkIcon from "@mui/icons-material/Work";
import LaunchIcon from "@mui/icons-material/Launch";
import { decode as base64_decode } from "base-64";
import { motion } from "framer-motion";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditIcon from "@mui/icons-material/Edit";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CandidateProfile() {
  const { candidateid } = useParams();
  const { user } = useAuthenticator((context) => [context.user]);
  const [candidate, setCandidateData] = useState([]);
  const [chooseBoxLink, setBoxLink] = useState("");
  const [chooseGender, setGender] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [marksSection, setMarksSection] = useState(false);
  const [candidatedetails, setCandidateDetails] = useState(null);
  const [docketApprovalRemarks, setDocketApprovalRemarks] = useState("");
  const [docketApproval, setDocketApproval] = useState("");
  const [open, setOpen] = React.useState(false);
  const [genderBlock, setGenderBlock] = useState(true);
  let jobCount = 1;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const boxLinkHandler = (event) => {
    setBoxLink(event.target.value);
  };

  const handleChangeGender = (event) => {
    setGender(event.target.value);
  };

  const handleChangeDocketApprovalRemarks = (event) => {
    setDocketApprovalRemarks(event.target.value);
  };

  const handleDocketApproval = (event) => {
    setDocketApproval(event.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(".").map(capitalizeFirst).join(" ");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    handleReport();
  }, []);

  const handleMarksSection = (id) => {
    window.scrollTo(0, 0);
    setMarksSection(true);
    let candidateArray = [];
    candidateArray.push(candidate[id]);
    setCandidateDetails(candidateArray);
  };

  async function handleReport() {
    setisLoading(true);
    try {
      const res = await axios.post(
        `${proxy}/api/candidateprofile`,
        {
          emailAddress: `${base64_decode(candidateid)}`,
          profile: user.attributes.profile,
        },
        {
          withCredentials: true,
          headers: {
            Authorization: user.signInUserSession.idToken.jwtToken,
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Credentials": true,
          },
        }
      );

      console.log("Data :--->", res.data.result);
      setBoxLink(res.data.result[0].boxLink)
      setDocketApprovalRemarks(res.data.result[0].approvalRemarks)
      setDocketApproval(res.data.result[0].approvalSelection)
      setisLoading(false);
      setCandidateData(res.data.result);
      setGenderBlock(false);
      if (res.data.result.length > 0) {
        window.scrollTo(0, 0);
        setMarksSection(true);
        let candidateArray = [];
        candidateArray.push(res.data.result[0]);
        setCandidateDetails(candidateArray);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function sendCandidateProfileData(formId) {
    try {
      const requestData = {
          formId: formId,
          gender: chooseGender,
          candidateId: base64_decode(candidateid),
      };

      if(chooseBoxLink) {
        requestData.boxLink = chooseBoxLink;
        requestData.boxLinkUpdatedDate = new Date();
        requestData.boxLinkUpdatedBy = user.username;
      }

      if(docketApproval || docketApprovalRemarks) {
        requestData.approvalSelection = docketApproval;
        requestData.approvalRemarks = docketApprovalRemarks;
        requestData.docketSubmittedBy = user.username;
        requestData.docketUpdatedDate = new Date();
      }

      const response = await axios.put(
        `${proxy}/api/candidateprofile`,requestData,
        {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: user.signInUserSession.idToken.jwtToken,
            "Access-Control-Allow-Credentials": true,
          },
        }
      );
      setOpen(true);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <React.Fragment>
      <div
        className="sections-header"
        style={{ height: "50px", background: "#fe414d" }}
      >
        <div className="sections-title-header">
          <p style={{ color: "white" }}>Candidate Profile</p>
        </div>
      </div>
      <div className="outer-div">
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Docket Data Updated at {new Date().toLocaleTimeString()} !!
          </Alert>
        </Snackbar>
        {isLoading && (
          <div style={{ height: "100%", margin: "auto" }}>
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div className="section">
            <div className="flex_items1">
              <div className="candidate_name_div profile">
                {candidate
                  .slice(0, 1)
                  .map(({ candidateName, emailAddress, gender, region, employmentType }) => {
                    return (
                      <div className="candidate_initial">
                        <Typography className="flex-content">
                          <span>Name: </span>
                          {candidateName}
                        </Typography>
                        <Typography className="flex-content">
                          <span>Region: </span>
                          {region}
                        </Typography>
                        <Typography className="flex-content">
                          <span>Employment Type: </span>
                          {employmentType}
                        </Typography>
                        {emailAddress && (
                          <Typography className="flex-content">
                            <span>Email: </span>
                            {emailAddress}
                          </Typography>
                        )}
                        {!emailAddress && (
                          <Typography className="flex-content">
                            <span>Email: </span>Email Not Set
                          </Typography>
                        )}
                        {genderBlock == false && (
                          <Typography className="flex-content">
                            <span>Gender: </span>
                            {gender}
                            <Tooltip title="Edit">
                              <EditIcon
                                style={{
                                  color: "#FA5F55",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => setGenderBlock(!genderBlock)}
                              />
                            </Tooltip>
                          </Typography>
                        )}
                        {genderBlock == true && (
                          <React.Fragment>
                            <Typography className="detail-heading">
                              Update Gender:{" "}
                            </Typography>
                            <FormControl variant="standard" required>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={chooseGender}
                                onChange={handleChangeGender}
                              >
                                <FormControlLabel
                                  value="Woman"
                                  control={<Radio />}
                                  label="Woman"
                                />
                                <FormControlLabel
                                  value="Man"
                                  control={<Radio />}
                                  label="Man"
                                />
                              </RadioGroup>
                            </FormControl>
                          </React.Fragment>
                        )}
                      </div>
                    );
                  })}
              </div>
              <div className="candidate_name_div profile">
                <div className="profile_grid profile_information">
                  {candidate.map((item, index) => {
                    return (
                      <Timeline className="timeline" key={item.formId}>
                        <TimelineItem className="timeline_firstItem">
                          <TimelineSeparator>
                            <TimelineDot className="timeline_dot_header">
                              <WorkIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography
                              variant="h6"
                              className="timeline_header"
                            >
                              Interview Details {jobCount++}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <CustomTimeLineSeparator />
                          <TimelineContent className="timeline-item">
                            {/* <Typography className='timelineItem_text'><span>Role : </span>{roleApplied}</Typography> */}
                            <motion.div whileHover={{ scale: 1.05 }}>
                              <div
                                className="timeline-item_content"
                                onClick={() => handleMarksSection(index)}
                              >
                                <Link
                                  style={{
                                    border:
                                      item.approvalSelection === "Approved"
                                        ? "1px solid #609966"
                                        : "1px solid #FA5F55",
                                  }}
                                >
                                  <div className="timeline-item_info">
                                    <div className="timeline-item_info-heading">
                                      <span>Role: </span>
                                      <h3 style={{ marginLeft: "40px" }}>
                                        {item.roleApplied}
                                      </h3>
                                    </div>
                                    <div className="timeline-item_info-heading">
                                      <span>Status: </span>
                                      <h3
                                        style={{
                                          marginLeft: "28px",
                                          color:
                                            item.status === "Qualified"
                                              ? "green"
                                              : item.status === "Reiteration"
                                              ? "orange"
                                              : "red",
                                        }}
                                      >
                                        {item.status}
                                      </h3>
                                    </div>
                                    {item.interviewType === "Domain" && (
                                      <div className="timeline-item_info-heading">
                                        <span>Score: </span>
                                        <h3
                                          style={{
                                            marginLeft: "32px",
                                            color:
                                              item.status === "Qualified"
                                                ? "green"
                                                : item.status === "Reiteration"
                                                ? "orange"
                                                : "red",
                                          }}
                                        >
                                          {item.totalScore}
                                        </h3>
                                      </div>
                                    )}
                                    <div className="timeline-item_info-heading">
                                      <span>Date: </span>
                                      <h3 style={{ marginLeft: "40px" }}>
                                        {new Date(
                                          item.interviewDate
                                        ).toLocaleDateString("en-us", {
                                          weekday: "long",
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                        })}
                                      </h3>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </motion.div>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineSeparator className="separator_padding">
                          <TimelineDot
                            variant="outlined"
                            className="timeline_dot"
                          />
                        </TimelineSeparator>
                      </Timeline>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex_items2 profile container_shadow">
              {marksSection && (
                <div className="profile_right section_content">
                  <Grid item className="section_title">
                    <h6 className="section_title_text">
                      Interview High Level Details
                    </h6>
                  </Grid>
                  <Grid item xs={12}>
                    {candidatedetails &&
                      candidatedetails.length > 0 &&
                      candidatedetails.map(
                        ({
                          formId,
                          interviewerName,
                          roleApplied,
                          status,
                          totalScore,
                          preferredRole,
                          marksObtained,
                          boxLink,
                          recruiterName,
                          interviewType,
                          approvalSelection,
                          docketSubmittedBy,
                          docketUpdatedDate,
                          approvalRemarks,
                          selectedDomains
                        }) => {
                          return (
                            <React.Fragment>
                              {!approvalSelection && (
                                <Chip
                                  style={{
                                    position: "fixed",
                                    top: "140px",
                                    right: "20px",
                                  }}
                                  icon={<VisibilityOffIcon />}
                                  color={
                                    status === "Disqualified"
                                      ? "error"
                                      : "warning"
                                  }
                                  label={
                                    status === "Disqualified"
                                      ? "Docket Review Not Required"
                                      : "Docket Not Reviewed"
                                  }
                                />
                              )}
                              {approvalSelection &&
                                status === "Qualified" &&
                                approvalSelection === "Approved" && (
                                  <Chip
                                    style={{
                                      position: "fixed",
                                      top: "140px",
                                      right: "20px",
                                    }}
                                    icon={<ThumbUpAltIcon />}
                                    color="success"
                                    label="Docket Approved"
                                  />
                                )}
                              {approvalSelection &&
                                status === "Qualified" &&
                                approvalSelection === "DisApproved" && (
                                  <Chip
                                    style={{
                                      position: "fixed",
                                      top: "140px",
                                      right: "20px",
                                    }}
                                    icon={<ThumbDownAltIcon />}
                                    color="error"
                                    label="Docket DisApproved"
                                  />
                                )}
                              {interviewType === "Domain" && (
                                <React.Fragment>
                                  <div className="detail-container">
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Result:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        <span
                                          style={{
                                            color:
                                              status === "Qualified"
                                                ? "green"
                                                : status === "Reiteration"
                                                ? "orange"
                                                : "red",
                                          }}
                                        >
                                          {status}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Total Score:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        <span
                                          style={{
                                            color:
                                              status === "Qualified"
                                                ? "green"
                                                : status === "Reiteration"
                                                ? "orange"
                                                : "red",
                                          }}
                                        >
                                          {totalScore}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Interview Type:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {interviewType} - {selectedDomains ? selectedDomains : "N/A"}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className="detail-container">
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Interviewer Name:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {caps(interviewerName)}
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Recruiter Name:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {caps(recruiterName)}
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Evaluated Role:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {roleApplied}
                                      </Typography>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                              {interviewType === "Phone" && (
                                <React.Fragment>
                                  <div className="detail-container">
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Result:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        <span
                                          style={{
                                            color:
                                              status === "Qualified"
                                                ? "green"
                                                : status === "Reiteration"
                                                ? "orange"
                                                : "red",
                                          }}
                                        >
                                          {status}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Interview Type:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {interviewType}
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box"></div>
                                  </div>
                                  <div className="detail-container">
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Interviewer Name:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {caps(interviewerName)}
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Recruiter Name:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {caps(recruiterName)}
                                      </Typography>
                                    </div>
                                    <div className="detail-container-box">
                                      <Typography className="detail-heading">
                                        Evaluated Role:{" "}
                                      </Typography>
                                      <Typography className="detail-content">
                                        {roleApplied}
                                      </Typography>
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                              <div className="detail-container">
                                <div className="detail-container-box">
                                  <Typography
                                    className="detail-box-link"
                                    style={{ marginBottom: "5px" }}
                                  >
                                    Enter Docket Link:{" "}
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    label="Docket Link"
                                    value={chooseBoxLink}
                                    onChange={boxLinkHandler}
                                    placeholder="Enter Docket Link"
                                    variant="standard"
                                    type="search"
                                    name="boxLink"
                                    disabled={boxLink}
                                  />
                                </div>
                                <div className="detail-container-box">
                                  {boxLink && (
                                    <Link
                                      href={boxLink}
                                      target="_blank"
                                      underline="hover"
                                    >
                                      <div className="btn_icon_container_box-link">
                                        <Tooltip title="Open Box Link">
                                          <LaunchIcon />
                                        </Tooltip>
                                      </div>
                                    </Link>
                                  )}
                                  {!boxLink && (
                                    <Tooltip title="Link not saved">
                                      <div className="btn_icon_container_box-link">
                                        <span style={{ color: "white" }}>
                                          N/A
                                        </span>
                                      </div>
                                    </Tooltip>
                                  )}
                                </div>
                                <div className="detail-container-box">
                                  <Typography className="detail-heading">
                                    Recommended Role:{" "}
                                  </Typography>
                                  <Typography className="detail-content">
                                    {preferredRole}
                                  </Typography>
                                </div>
                              </div>
                              {status === "Qualified" && (
                                <div className="detail-container">
                                  <div className="detail-container-box">
                                    <Typography className="detail-heading">
                                      Docket Remarks:
                                    </Typography>
                                    <TextField
                                      style={{ width: "200%" }}
                                      id="outlined-multiline-static"
                                      variant="standard"
                                      autoFocus
                                      placeholder="Write Docket Remarks ...."
                                      multiline
                                      rows={2}
                                      value={docketApprovalRemarks}
                                      onChange={
                                        handleChangeDocketApprovalRemarks
                                      }
                                      name="remarks"
                                      disabled={approvalRemarks}
                                    />
                                  </div>
                                  <div className="detail-container-box"></div>
                                  <div className="detail-container-box">
                                    <Typography className="detail-heading">
                                      Docket Approval:{" "}
                                    </Typography>
                                    <FormControl
                                      variant="standard"
                                      required
                                      sx={{ m: 1, minWidth: 120 }}
                                    >
                                      <InputLabel id="demo-simple-select-standard-label">
                                        -- Select --
                                      </InputLabel>
                                      <Select
                                        autoFocus
                                        labelId="demo-simple-select-required-label"
                                        id="demo-simple-select-required"
                                        name="marks"
                                        value={docketApproval}
                                        disabled={approvalSelection}
                                        onChange={handleDocketApproval}
                                        style={{ height: "40px" }}
                                        label="-- Select --"
                                      >
                                        <MenuItem
                                          key={0}
                                          value="Approved"
                                          style={{ color: "green" }}
                                        >
                                          Approved
                                        </MenuItem>
                                        <MenuItem
                                          key={1}
                                          value="DisApproved"
                                          style={{ color: "red" }}
                                        >
                                          DisApproved
                                        </MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                              )}
                              <div className="detail-container">
                                {approvalSelection && (
                                  <div className="detail-container-box">
                                    <Typography className="detail-heading">
                                      Docket Submitted By:{" "}
                                    </Typography>
                                    <Typography
                                      className="detail-content"
                                      style={{
                                        color: docketSubmittedBy
                                          ? "green"
                                          : "red",
                                      }}
                                    >
                                      {docketSubmittedBy
                                        ? caps(docketSubmittedBy)
                                        : "Not Submitted Yet"}
                                    </Typography>
                                  </div>
                                )}
                                <div className="detail-container-box">
                                  {approvalSelection && (
                                    <div className="detail-container-box">
                                    <Typography className="detail-heading">
                                      Docket Updated Date:{" "}
                                    </Typography>
                                    <Typography
                                      className="detail-content"
                                    >
                                      {docketUpdatedDate
                                        ? docketUpdatedDate
                                        : "Not Submitted Yet"}
                                    </Typography>
                                  </div>
                                  )}
                                </div>
                                <div className="detail-container-box">
                                  <div className="detail-container-update-button">
                                    <div className="details-container-button-box">
                                      <div className="button_container">
                                        <Button
                                          onClick={() =>
                                            sendCandidateProfileData(formId)
                                          }
                                          className={approvalSelection ? 'custom_btn_grey' : 'custom_btn_blue'}
                                          disabled={approvalSelection}
                                          endIcon={
                                            <div className="btn_icon_container">
                                              <SendIcon />
                                            </div>
                                          }
                                        >
                                          <span className="btn_text">
                                            Update
                                          </span>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {interviewType === "Domain" && (
                                <React.Fragment>
                                  <Grid item>
                                    <h6 className="section_title_text">
                                      Section Wise Marks Dropdown
                                    </h6>
                                  </Grid>
                                  <div style={{ margin: "auto", width: "75%" }}>
                                    <Tooltip
                                      title={
                                        expanded == false
                                          ? "Click to expand"
                                          : "Click to collapse"
                                      }
                                    >
                                      <Accordion
                                        expanded={expanded === "panel1"}
                                        onChange={handleChange("panel1")}
                                      >
                                        <AccordionSummary
                                          expandIcon={
                                            expanded == false ? (
                                              <ExpandMoreIcon />
                                            ) : (
                                              <ExpandLessIcon />
                                            )
                                          }
                                          aria-controls="panel4bh-content"
                                          id="panel4bh-header"
                                        >
                                          <Typography
                                            sx={{ fontWeight: "bold" }}
                                          >
                                            Marks Dropdown
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <TableContainer component={Paper}>
                                            <Table
                                              sx={{ minWidth: "650" }}
                                              aria-label="Simple Table"
                                            >
                                              <TableHead
                                                style={{
                                                  backgroundColor: "#FA5F55",
                                                }}
                                              >
                                                <TableRow>
                                                  <TableCell
                                                    style={{ color: "white" }}
                                                  >
                                                    <b>Section Name</b>
                                                  </TableCell>
                                                  <TableCell
                                                    align="right"
                                                    style={{ color: "white" }}
                                                  >
                                                    <b>Ratings</b>
                                                  </TableCell>
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                {marksObtained &&
                                                  marksObtained.map(
                                                    (marks, index) => {
                                                      return (
                                                        <TableRow
                                                          sx={{
                                                            "&:last-child td, &:last-child th":
                                                              { border: 0 },
                                                          }}
                                                        >
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                          >
                                                            {marks[0]}
                                                          </TableCell>
                                                          <TableCell align="right">
                                                            {marks[1] === 0
                                                              ? "No Experience"
                                                              : marks[1] === 1
                                                              ? "Beginner"
                                                              : marks[1] === 2
                                                              ? "Intermediate"
                                                              : marks[1] === 3
                                                              ? "Proficient"
                                                              : "Expert"}
                                                          </TableCell>
                                                        </TableRow>
                                                      );
                                                    }
                                                  )}
                                              </TableBody>
                                            </Table>
                                          </TableContainer>
                                        </AccordionDetails>
                                      </Accordion>
                                    </Tooltip>
                                  </div>
                                </React.Fragment>
                              )}
                            </React.Fragment>
                          );
                        }
                      )}
                  </Grid>
                </div>
              )}
              {!marksSection && (
                <h1 className="marks-section__else-content">
                  Select a Career Stage to View Details
                </h1>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export const CustomTimeLineSeparator = () => (
  <TimelineSeparator className="separator_padding">
    <TimelineDot variant="outlined" className="timeline_dot" />
    <TimelineConnector />
  </TimelineSeparator>
);

export default CandidateProfile;
