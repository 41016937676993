import {React , useEffect} from 'react';
import Home from './Components/Home'
import Navbar from './Components/Navbar';
import CreateForm from './Components/CreateForm';

import { CXORoute, CXORouteAndInterviewerRoute } from './Components/ProtectedRoute';
import Template from './Components/Template';
import { Switch,Route} from "react-router-dom";
import Form from './Components/Form';
import SectionsDisplayer from './Components/SectionsDisplayer';
import SubSectionEditor from './Components/SubSectionEditor';
import SectionEditor from './Components/SectionEditor';
import SubSectionAdder from './Components/SubSectionAdder';
import SectionAdder from './Components/SectionAdder';
import BlocksDisplayer from './Components/BlocksDisplayer';
import BlockView from './Components/BlockView';
// import { ProfileContent } from './ProfileContent';
import RolesDisplayer from './Components/RolesDisplayer';
import RoleAdder from './Components/RoleAdder';
import RoleEditor from './Components/RoleEditor';
import TemplatesDisplayer from './Components/TemplatesDisplayer';
import FormView from './Components/FormView';
import CandidateDetails from './Components/CandidateDetailsPage';
import InterviewerForm from './Components/InterviewerForm';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { Image, useTheme } from "@aws-amplify/ui-react";
import {AdminRoute, InterviewerRoute} from "./Components/ProtectedRoute"
import UnAuthorised from './Components/UnAuthorised';
import Profile from './Components/Profile';
import { ProtectedRoute } from './Components/ProtectedRoute';
// import ResponsiveDrawer from './Components/SideBar';
import InterviewerFormSearch from './Components/InterviewerFormSearch';
import BlockEdit from './Components/BlockEdit';
import Reports from './Components/charts/Reports';
import InterviewFormEdit from './Components/InterviewFormEdit';
import ReportLandinPage from './Components/charts/ReportLanding';
import PanelistReports from './Components/charts/PanelistReports';
import InterviewerFormSearchCXO from './Components/InterviewFormSearchCXO';
import InterviewerProfile from './Components/InterviewerProfile';
import CandidateProfile from './Components/CandidateProfile';
import InterviewForm from './Components/Interview Form/InterviewForm';
import CandidateDetailsPage from './Components/Interview Form/CandidateDetailsPage';
import PanelistUtilization from './Components/charts/PanelistUtilization';
import PanelReport from './Components/charts/PanelReport';
import UpdateInterviewerPage from './Components/UpdateInterviewerPage';
import UploadBulkData from './Components/reactToPDF/BulkUploadData';
import CandidateDetailsPagePhone from './Components/Interview Form/CandidateDetailsPagePhone';
import PhoneInterviewPage from './Components/Interview Form/PhoneInterviewPage';
import HiringPOCUtilization from './Components/charts/HiringPOCUtilization';
import { Hub } from 'aws-amplify';
import axios from 'axios';
import { proxy } from './proxy';
import { Auth } from 'aws-amplify';
import DomainReportCXO from './Components/charts/DomainReportCXO';

 function Header() {
  const { tokens } = useTheme();

  return (
    <Image
      alt="logo"
      src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
      padding={tokens.space.medium}
    />
  );
}
Amplify.configure({
  Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId:process.env.REACT_APP_IDENTITYPOOLID,
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_USERPOOLID ,

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_USERPOOLWEBCLIENTID,

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: true,
      oauth: {
          domain:process.env.REACT_APP_DOMAIN,
          scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: 'https://pshiringprobeformdev.psengineering.io/',
          redirectSignOut: 'https://pshiringprobeformdev.psengineering.io/',
          responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
  }
})
function App() {

  let user;
useEffect(() => {
  // user=useAuthenticator((context)=> [context.user])
  Hub.listen('auth', async ({ payload: { event } }) => {
    if (event === 'signIn') {
      // Call your function or trigger any action here
      await handleLoginSuccess();
    }
  });

  // Clean up the event listener when the component unmounts
  return () => {
    Hub.remove('auth');
  };

},[])

const addNewUser = async ()=>{
  let isAdmin, isCXO , isInterviewer;
  const isHiringManager=user.attributes['custom:isHiringManager']==="true";
  const isPhoneInterviewer=user.attributes['custom:interviewType']=="phone";
  console.log(user.attributes['custom:interviewtype']);
  let data;
  let roles=[]
  if(user!==undefined && user.signInUserSession.idToken.payload['cognito:groups']!==undefined){
    roles=user.signInUserSession.idToken.payload['cognito:groups'];
  }
  if(roles.includes("Interviewer")){
    console.log("interviewer");
    isAdmin=roles.includes("Admin");
    isCXO=roles.includes("CXO");
    isInterviewer=roles.includes("Interviewer");
    let details;
    axios.post(`${proxy}/api/interviewerList/${user.username}`, {
      withCredentials: true,
      headers: {
        'Authorization':user.signInUserSession.idToken.jwtToken,
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    }).then((res) => {
      // console.log(res.data.result[0]);
      details=res.data.result[0];
      console.log(details);
    data={
      username: user.username,
      fullName: user.attributes.name,
      isAdmin: isAdmin,
      isCXO: isCXO,
      isInterviewer: isInterviewer,
      isHiringManager: isHiringManager,
      isPhoneInterviewer: isPhoneInterviewer,
      profile: user.attributes.profile,
      active: 'true',
      careerStage: details.positionTitle,
      rolesAllowed: details.roleTypeAllowed,
      cloudExpertise: details.interviewerExpertise,
      status:details.updatedStatus,
      remarks: details.remarks
    }
    console.log(data);
    try{
      console.log(data);
    axios
    .post(`${proxy}/api/userdetails`,data, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
    }
    catch(err){
      console.log(err);
    }
    }).catch((error)=>{
      data={
        username: user.username,
        fullName: user.attributes.name,
        isAdmin: isAdmin,
        isCXO: isCXO,
        isInterviewer: isInterviewer,
        isHiringManager: isHiringManager,
        isPhoneInterviewer: isPhoneInterviewer,
        profile: user.attributes.profile,
        active: 'true'
      }
      try{
        console.log(data);
      axios
      .post(`${proxy}/api/userdetails`,data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      }
      catch(err){
        console.log(err);
      }
  
      
    });
    console.log(data);
    

  }
  else{
    isAdmin=roles.includes("Admin");
    isCXO=roles.includes("CXO");
    isInterviewer=roles.includes("Interviewer");
    data={
      username: user.username,
      fullName: user.attributes.name,
      isAdmin: isAdmin,
      isCXO: isCXO,
      isInterviewer: isInterviewer,
      isHiringManager: isHiringManager,
      profile: user.attributes.profile,
      active: 'true'
    }
    try{
      console.log(data);
    axios
    .post(`${proxy}/api/userdetails`,data, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        Authorization: user.signInUserSession.idToken.jwtToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
    })
    }
    catch(err){
      console.log(err);
    }

  }
  

  
  

}
const updateUser = async() => {
  try{
  axios
  .put(`${proxy}/api/userdetails`,{
    "lastLogin": Date.now(),
  }, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      Authorization: user.signInUserSession.idToken.jwtToken,
      "Content-Type": "application/json",
      "Access-Control-Allow-Credentials": true,
    },
  })
  }
  catch(err){
    console.log(err);
  }
}
const addUserDetails = async ()=>{
      axios
  .get(`${proxy}/api/userdetails/${user.username}`, {
    withCredentials: true,
    headers: {
      Authorization: user.signInUserSession.idToken.jwtToken,
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true,
    },
  })
  .then((res) => {
    console.log(res)
    if(res.data.msg==="Not Found")
    {
      console.log("add user");
      addNewUser();
    }
    else
    {
      updateUser();
    }

  })
  .catch((error) => {
    console.log(error);
  });
  console.log(user.username);

}

const handleLoginSuccess = async () => {
  // Perform any actions or call any functions here after successful login
  console.log("login Successfully")
  user = await Auth.currentAuthenticatedUser();
  console.log(user);
  addUserDetails();
  // Call your function or trigger any action here
  // ...
};
  return (

      <div className="App" >
        <Navbar/>

    <Authenticator signUpAttributes={['email']} components={Header} >
      {({ signOut, user }) => (
          <div >
            <Switch >
              <CXORoute exact path="/reports/domains" component={DomainReportCXO}/>
              <InterviewerRoute exact path="/candidatedetails" component={CandidateDetailsPage}/>
              <InterviewerRoute exact path="/candidatedetailsphone" component={CandidateDetailsPagePhone}/>
              <InterviewerRoute exact path="/interviewformphone/:formId" component={PhoneInterviewPage}/>
              <InterviewerRoute exact path="/interviewform/:formid" component={InterviewForm}/>
              <AdminRoute exact  path="/blocks/:id" component={BlockView}/>
              <Route exact path="/profile" component={Profile}/>
              <AdminRoute exact path="/block" component={BlockEdit}/>
              <AdminRoute exact path="/createform" component={CreateForm}/>
              <AdminRoute exact path="/updateInterviewer" component={UpdateInterviewerPage}/>
              <InterviewerRoute exact path="/filledforms" component={InterviewerFormSearch}/>
              <CXORoute exact path="/filledforms/cxo" component={InterviewerFormSearchCXO}/>
              <AdminRoute exact path="/updateInterviewer" component={UpdateInterviewerPage}/>
              {/* <CXORoute exact path="/filledforms" component={InterviewerFormSearch}/> */}

              <AdminRoute exact path="/addtemplate/:templateid" component={Template}/>
              <AdminRoute exact path="/form/:templateid" component={Form}/>
              <AdminRoute exact path="/upload" component={UploadBulkData}/>
              <ProtectedRoute exact path="/" component={Home} user={user}/>
              {/* <Route exact  path="/template/new" component={Form}/> */}
              <AdminRoute exact  path="/template/:templateid" component={FormView}/>
              <AdminRoute exact path="/sections" component={SectionsDisplayer}/>
              <AdminRoute  exact path="/:section/:sectionid/:subsection/:subsectionid" component={SubSectionEditor}/>
              <CXORoute exact path="/reports/chart" component={Reports}/>
              <CXORoute exact path="/reports/panel" component={PanelistReports}/>
              <CXORoute exact path="/reports/utilization" component={PanelistUtilization} />
              <CXORoute exact path="/reports/recruiterutilization" component={HiringPOCUtilization} />
              <CXORoute exact path="/reports/panelreport" component={PanelReport} />
              <CXORoute exact path="/reports/interviewpanel" component={InterviewerProfile}/>
              <CXORoute exact path="/reports/candidate/:candidateid" component={CandidateProfile}/>
              {/* <InterviewerRoute exact path="/interviewform/:formId" component={InterviewFormEdit}/> */}
              <AdminRoute  exact path="/:section/:sectionid" component={SectionEditor}/>
              <AdminRoute exact  path="/addsubsection/:section/:sectionid" component={SubSectionAdder}/>
              <AdminRoute exact  path="/addsection" component={SectionAdder}/>
              <AdminRoute exact  path="/blocks" component={BlocksDisplayer}/>
              <AdminRoute exact  path="/roles" component={RolesDisplayer}/>
              <AdminRoute exact  path="/addrole" component={RoleAdder}/>
              <AdminRoute exact  path="/:role/:roleid" component={RoleEditor}/>
              <AdminRoute exact  path="/templates" component={TemplatesDisplayer}/>
              {/* <InterviewerRoute exact path="/candidate" component={CandidateDetails}/>
              <InterviewerRoute exact path="/interviewerform" component={InterviewerForm}/> */}
              <Route exact path="/unauthorised" component={UnAuthorised}/>
              <CXORoute exact path="/reports" component={ReportLandinPage}/>

            </Switch>
          </div>      )}
      </Authenticator>
        

      </div>

  );
}
export default App;
