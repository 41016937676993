import * as React from "react";
import { useEffect } from "react";
import { proxy } from "../../proxy";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import InterviewFormSectionPage from "./InterviewFormSectionPage";
import { styled } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { blue } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import Modal from "./Modal.js";
import "./InterviewSectionNav.css";
import Loader from "../Loader";
import PeopleIcon from "@mui/icons-material/People";
import GradingIcon from "@mui/icons-material/Grading";
import { Fab, Tooltip } from "@mui/material";
import CandidateModal from "./CandidateModal";
import ProficiencyModal from "./ProficiencyModal";

export default function InterviewFormSectionNav({ formid }) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [sectionData, setSectionData] = useState([]);
  const [form, setForm] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [active, setActive] = useState(false);
  const [blockId, setBlockId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [score, setScoreData] = useState([]);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCandidateModal, setCandidateModal] = useState(false);
  const [showProficiencyModal, setProficiencyModal] = useState(false);
  const [didBlockSave, setDidBlockSave] = useState(false);

  const RedColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(red["A400"]),
    backgroundColor: red["A400"],
    "&:hover": {
      backgroundColor: red["A200"],
    },
    height: "60px",
    // minWidth:"200px",
    // maxWidth:"fit-content"
  }));

  const BlueColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700],
    },
    height: "40px",
    // minWidth:"200px",
    // maxWidth:"fit-content"
  }));

  const GreenColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[700],
    "&:hover": {
      backgroundColor: green[800],
    },
    height: "60px",
    // minWidth:"200px",
    // maxWidth:"fit-content",
    cursor: "pointer",
  }));

  const GrayColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(grey[700]),
    backgroundColor: grey[700],
    "&:hover": {
      backgroundColor: grey[800],
    },
    height: "60px",
    // minWidth:"200px",
    // maxWidth:"fit-content",
    cursor: "pointer",
  }));
  useEffect(() => {
    axios
      .get(`${proxy}/api/candidatedetails/${formid}`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setisLoading(false);
        console.log(res.data.result);
        setForm(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //   let currentScore = 0.0;
  //   if(form.blocks.length > 0) {
  //     for(let i = 0 ;i < form.blocks.length ; i++){
  //         currentScore=currentScore+form.blocks[i].weightage*(form.blocks[i].marks/4);
  //         }
  //     }
  //   else {
  //         currentScore = 0.0
  //     }

  function checkAllValuePresent(data){
    for (const key in data) {
      if (!data[key]) {
        return false;
      }
    }
    return true;
  }

  let currentScore = () => {
    let currentScore = 0.0;
    if (form.blocks.length > 0) {
      for (let i = 0; i < form.blocks.length; i++) {
        currentScore =
          currentScore + form.blocks[i].weightage * (form.blocks[i].marks / 4);
      }
    }
    return currentScore;
  };

  let validate = () => {
    for (let i = 0; i < form.blocks.length; i++) {
      if (form.blocks[i].remarks === "" || (!checkAllValuePresent(form.blocks[i].recommendedProbeAreas))) {
        return false;
      }
    } 
    return true;
  };

  const handleClick = (e) => {
    console.log(e.currentTarget.value);
    setActive(true);
    setBlockId(e.currentTarget.value);
  };

  const getTotalScoreData = async () => {
    try {
      const res = await axios.get(`${proxy}/api/getsectiondata/${formid}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      });
      console.log("ScoreData from Section Nav===>", res.data.result);
      setScoreData(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitClick = () => {
    getTotalScoreData();
    setShowModal(true);
    setShowConfetti(true);
  };
  const didSave = async () => {
    setisLoading(true);
    try {
      const res = await axios.get(`${proxy}/api/candidatedetails/${formid}`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      });
      setisLoading(false);
      console.log("Formdata:", res.data.result);
      setForm(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* {isLoading && <Loader/>}
        {!isLoading &&  */}
      <div className="sections-nav-wrapper">
        <div className="form-icon">
          <Tooltip title="Candidate Details">
            <PeopleIcon
              onClick={() => {
                setCandidateModal(true);
              }}
              style={{ width: "100%", height: "90%", cursor: "pointer" }}
            />
          </Tooltip>
        </div>
        <div className="sections-nav" style={{ width: "80%" }}>
          {form &&
            form.blocks &&
            form.blocks.map((data, index) => {
              return (
                <React.Fragment>
                  {data.recommendedProbeAreas && 
                <div>
                  {data.marks >= 0 && data.remarks !== "" && checkAllValuePresent(data.recommendedProbeAreas) && (
                    <GreenColorButton
                      key={index}
                      variant="contained"
                      style={{ color: "white", fontWeight: "bold" }}
                      value={data._id}
                      onClick={handleClick}
                    >
                      {data.sectionName}
                    </GreenColorButton>
                  )}
                  {(data.marks < 0 || data.remarks === "" || !checkAllValuePresent(data.recommendedProbeAreas)) && (
                    <RedColorButton
                      key={index}
                      variant="contained"
                      style={{ color: "white", fontWeight: "bold" }}
                      value={data._id}
                      onClick={handleClick}
                    >
                      {data.sectionName}
                    </RedColorButton>
                  )}
                </div> }
                {!data.recommendedProbeAreas && 
                <div>
                  {data.marks >= 0 && data.remarks !== "" && (
                    <GreenColorButton
                      key={index}
                      variant="contained"
                      style={{ color: "white", fontWeight: "bold" }}
                      value={data._id}
                      onClick={handleClick}
                    >
                      {data.sectionName}
                    </GreenColorButton>
                  )}
                  {(data.marks < 0 || data.remarks === "") && (
                    <RedColorButton
                      key={index}
                      variant="contained"
                      style={{ color: "white", fontWeight: "bold" }}
                      value={data._id}
                      onClick={handleClick}
                    >
                      {data.sectionName}
                    </RedColorButton>
                  )}
                </div> }
                </React.Fragment>
              );
            })}
        </div>
        <div className="form-icon">
          <Tooltip title="Proficiency Matrix">
            <GradingIcon
              onClick={() => {
                setProficiencyModal(true);
              }}
              style={{ width: "100%", height: "90%", cursor: "pointer" }}
            />
          </Tooltip>
        </div>
      </div>
      {/* }  */}
      <div style={{ width: "100%" }}>
        <CandidateModal
          showCandidateModal={showCandidateModal}
          setCandidateModal={setCandidateModal}
          formid={formid}
        />
        <ProficiencyModal
          showProficiencyModal={showProficiencyModal}
          setProficiencyModal={setProficiencyModal}
        />
        {active && (
          <div>
            <Modal
              showModal={showModal}
              setShowModal={setShowModal}
              showConfetti={showConfetti}
              setShowConfetti={setShowConfetti}
              scoreData={score}
              formId={formid}
              currentRole={form.role}
              selectedDomain={form.selectedDomains}
            />
            <div style={{ width: "100%", marginTop: "20px" }}>
              <InterviewFormSectionPage
                key={blockId}
                blockId={blockId}
                formId={formid}
                didSave={didSave}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {validate() == true && (
                <Tooltip title="Submit Enabled">
                  <GreenColorButton
                    style={{
                      marginLeft: "30px",
                      marginTop: "30px",
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                    onClick={handleSubmitClick}
                  >
                    Submit
                  </GreenColorButton>
                </Tooltip>
              )}
              {validate() == false && (
                <Tooltip title="Submit Disabled">
                  <GrayColorButton
                    disabled={validate() == false}
                    style={{
                      marginLeft: "30px",
                      marginTop: "30px",
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Submit
                  </GrayColorButton>
                </Tooltip>
              )}
            </div>
            <Tooltip title="Total Marks">
              <Fab
                backgroundColor="#aeea00"
                aria-label="add"
                style={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  backgroundColor: "#000",
                }}
              >
                <span style={{ color: "white" }}> {currentScore()} </span>
              </Fab>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}
