import { useAuthenticator } from "@aws-amplify/ui-react";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@aws-amplify/ui-react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import { proxy } from "../proxy";
import { useEffect } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import BlackButton from "./BlackButton";
import { tidy, distinct, filter } from "@tidyjs/tidy";
import { Grid, Switch, Tooltip, Typography } from "@mui/material";
import InterviewerFormView from "./InterviewerFormView";
import Loader from "./Loader";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TablePagination from "@mui/material/TablePagination";
import { useHistory } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import { encode as base64_encode } from "base-64";
import "../styles/InterviewFormSearch.css";
import { useSelector } from "react-redux";
function InterviewerFormSearchCXO() {
  let date = new Date();
  date.setDate(date.getDate() - 30);

  const { user } = useAuthenticator((context) => [context.user]);
  const profile= useSelector((state) => {
    return state.profile;
  });
  const [candidateName, setCandidateName] = React.useState(null);
  const [interviewerName, setInterviewerName] = React.useState(null);
  const [role, setRole] = React.useState(null);
  const [isLoading, setisLoading] = React.useState(false);
  const [formData, setFormData] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [displayingData, setDisplayingData] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [interviewers, setInterviewers] = React.useState([]);
  const [roles, setRoles] = React.useState([]);
  const [display, setDisplay] = React.useState(false);
  const [toDisplay, setToDisplay] = React.useState(false);
  const [showHeader, setShowHeader] = React.useState(false);
  const [templates, setTemplates] = React.useState([]);
  const [templateName, setTemplateName] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [statuses, setstatuses] = React.useState([
    "DisQualified",
    "Reiteration",
    "Qualified",
  ]);
  const [employmentType, setEmploymentType] = React.useState(null)
  const [employmentTypes, setEmploymentTypes] = React.useState([
    "FTE","FXTE","Contractor","Spring"
  ])
  const [interviewType, setInterviewType] = React.useState(null);
  const [interviewTypes, setInterviewTypes] = React.useState([]);
  const [startValue, setStartValue] = React.useState(date);
  const [endValue, setEndValue] = React.useState(new Date());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [getExcelData, setExcelData] = React.useState([]);
  const candidateHistory = useHistory();
  const [order, setOrder] = React.useState("ASC");
  const [showDuplicates, setShowDuplicates] = React.useState(true);
  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];

  const toggleDuplicates = () => {
    setShowDuplicates(!showDuplicates);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  function initialiseArrays(data) {
    let candidates = [];
    let interviewers = [];
    let roles = [];
    let templates = [];
    let interviewTypes = [];
    if (data !== null && data !== undefined) {
      data.map(
        ({
          candidateName,
          interviewerName,
          role,
          templateName,
          interviewType,
        }) => {
          candidates.push(candidateName);
          interviewers.push(interviewerName);
          roles.push(role);
          templates.push(templateName);
          interviewTypes.push(interviewType);
          return 1;
        }
      );
    }

    setCandidates(tidy(candidates, distinct([])));
    setInterviewers(tidy(interviewers, distinct([])));
    setRoles(tidy(roles, distinct([])));
    setTemplates(tidy(templates, distinct([])));
    setInterviewTypes(tidy(interviewTypes, distinct([])));
    return true;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getSearchFormsResult();
  }, [profile]);

  useEffect(() => {
    setdisplayabledata();
  }, [showDuplicates]);

  const getSearchFormsResult = async () => {
    if(ableToSwitch){
    try {
      setisLoading(true);
      const res = await axios
        .put(
          `${proxy}/api/forms`,
          {
            startDate: startValue,
            endDate: endValue,
            profile: profile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((res) => {
          console.log(res.data.result);
          setData(res.data.result);
          setDisplayingData(res.data.result);
          setisLoading(false);
          initialiseArrays(res.data.result);
          setToDisplay(false);
          setShowHeader(true);
          setDisplay(false);
          setPage(0);
        });
    } catch (error) {
      setisLoading(false);
      console.log("Error getting filtered data.");
    }
  }else{
    try {
      setisLoading(true);
      const res = await axios
        .put(
          `${proxy}/api/forms`,
          {
            startDate: startValue,
            endDate: endValue,
            profile: presentProfile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((res) => {
          console.log(res.data.result);
          setData(res.data.result);
          setDisplayingData(res.data.result);
          setisLoading(false);
          initialiseArrays(res.data.result);
          setToDisplay(false);
          setShowHeader(true);
          setDisplay(false);
          setPage(0);
        });
    } catch (error) {
      setisLoading(false);
      console.log("Error getting filtered data.");
    }
  }
  };

  
  const getCurrentDateTimeString = () => {
    const currentDate = new Date();
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Asia/Kolkata',
    };
    return new Intl.DateTimeFormat('en-IN', options).format(currentDate).replace(/[/:]/g, ''); // Format the date and remove separators
  };

  const convertToExcel = (displayData) => {
    console.log("displayData", displayData)
    try {
      const filteredData = displayData.map(excel =>{
        const candidateDict = {};
      candidateDict['Candidate Name'] = excel['candidateName'] || '';
      candidateDict['Email Address'] = excel['emailAddress'] || '';
      candidateDict['Interviewer Name'] = excel['interviewerName'] || '';
      candidateDict['Hiring POC'] = excel['recruiterName'] || '';
      candidateDict['Interview Type'] = excel['interviewType'] || '';
      candidateDict['Cloud Type'] = excel['templateName'] || '';
      candidateDict['Role'] = excel['role'] || '';
      candidateDict['Form Submitted Date'] = String(excel['timestamp'] || '');
      candidateDict['Status'] = excel['status'] || '';
      candidateDict['Employment Type'] = excel['employmentType'] || '';
      if (excel['interviewType'] === 'Domain') {
        candidateDict['Total Score'] = excel['totalScore'] || '';
      }
      candidateDict['Interview Date'] = String(excel['interviewDate'] || '');
      candidateDict['Recommended Role'] = excel['preferredRole'] || '';
      candidateDict['Gender'] = excel['gender'] || '';
      candidateDict['Approval'] = excel['approvalSelection'] || '';
      candidateDict['Docket Submitted By'] = excel['docketSubmittedBy'] || '';
      candidateDict['Docket Submitted Date'] = excel['docketUpdatedDate'] || '';
      return candidateDict;
      })
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const currentDateTimeString = getCurrentDateTimeString();
      const data = new Blob([excelBuffer], { type: "xlsx" });
      FileSaver.saveAs(data, `Evaluation_Dump-${currentDateTimeString}.xlsx`);
    } catch (error) {
      alert("Download Status : Error Downloading");
    }
  };

  const handleChangeCandidateName = (event, newvalue) => {
    setCandidateName(newvalue);
  };
  const handleChangeTemplateName = (event, newvalue) => {
    setTemplateName(newvalue);
  };
  const handleChangeInterviewerName = (event, newvalue) => {
    setInterviewerName(newvalue);
  };
  const handleChangeRole = (event, newvalue) => {
    setRole(newvalue);
  };
  const handleChangeStatus = (event, newvalue) => {
    setStatus(newvalue);
  };

  const handleChangeEmploymentType = (event, newvalue) => {
    setEmploymentType(newvalue);
  };
  const handleChangeInterviewType = (event, newvalue) => {
    setInterviewType(newvalue);
  };

  const capitalizeFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const caps = (str) => {
    return str.split(" ").map(capitalizeFirst).join(" ");
  };

  const setdisplayabledata = () => {
    let datatobedisplayed = data;

    if (
      candidateName !== null &&
      candidateName !== "" &&
      candidateName !== undefined
    ) {
      datatobedisplayed = tidy(
        data,
        filter((d) => d.candidateName === candidateName)
      );
    }

    if (
      templateName !== null &&
      templateName !== "" &&
      templateName !== undefined
    ) {
      datatobedisplayed = tidy(
        data,
        filter((d) => d.templateName === templateName)
      );
    }

    if (
      interviewerName !== null &&
      interviewerName !== "" &&
      interviewerName !== undefined
    ) {
      datatobedisplayed = tidy(
        datatobedisplayed,
        filter((d) => d.interviewerName === interviewerName)
      );
    }
    if (role !== null && role !== "" && role !== undefined) {
      datatobedisplayed = tidy(
        datatobedisplayed,
        filter((d) => d.role === role)
      );
    }
    if (status !== null && status !== "" && status !== undefined) {
      if (status === "DisQualified") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.status === "Disqualified")
        );
      } else if (status === "Reiteration") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.status === "Reiteration")
        );
      } else if (status === "Qualified") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.status === "Qualified")
        );
      }
    }

    if (employmentType !== null && employmentType !== "" && employmentType !== undefined) {
      if (employmentType === "FTE") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.employmentType === "FTE")
        );
      } else if (employmentType === "FXTE") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.employmentType === "FXTE")
        );
      } else if (employmentType === "Contractor") {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.employmentType === "Contractor")
        );
      } else {
        datatobedisplayed = tidy(
          datatobedisplayed,
          filter((d) => d.employmentType === "Spring")
        );
      }
    }

    if (
      interviewType !== null &&
      interviewType !== "" &&
      interviewType !== undefined
    ) {
      datatobedisplayed = tidy(
        datatobedisplayed,
        filter((d) => d.interviewType === interviewType)
      );
    }

    if (!showDuplicates) {
      datatobedisplayed = datatobedisplayed.reduce((uniqueData, item) => {
        if (!item.emailAddress) {
          return [...uniqueData, item];
        }

        const existingItem = uniqueData.find(
          (dataItem) => dataItem.emailAddress === item.emailAddress
        );
        if (
          !existingItem ||
          new Date(item.interviewDate) > new Date(existingItem.interviewDate)
        ) {
          return [
            ...uniqueData.filter(
              (dataItem) => dataItem.emailAddress !== item.emailAddress
            ),
            item,
          ];
        }
        return uniqueData;
      }, []);
    }

    setShowHeader(true);
    setDisplayingData(datatobedisplayed);
    initialiseArrays(data);
    setDisplay(false);
    setPage(0);
  };

  const sortingNumber = (col) => {
    if (order === "ASC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] > b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setOrder("DSC");
    }

    if (order === "DSC") {
      const sorted = [...displayingData].sort((a, b) =>
        a[col] < b[col] ? 1 : -1
      );
      setDisplayingData(sorted);
      setOrder("ASC");
    }
  };
  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "fit-content", margin: "auto" }}>
        <div
          className="sections-header"
          style={{ height: "50px", background: "#fe414d" }}
        >
          <div className="sections-title-header">
            <p style={{ color: "white" }}>Search Forms</p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!toDisplay && (
            <Tooltip title="Open Filters">
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowDownIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
          {toDisplay && (
            <Tooltip title="Close Filter">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
        </div>

        {toDisplay && (
          <motion.div
            key="box"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ type: "spring", duration: 2.5 }}
            className="box"
          >
            <div style={{ padding: "5px", width: "100%", marginLeft: "35%" }}>
              <Grid container spacing={1} style={{}}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(new Date(newValue).toISOString());
                      }}
                    />
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      maxDate={new Date()}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(new Date(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                  <BlackButton
                    style={{ marginLeft: "6%" }}
                    onClick={getSearchFormsResult}
                  >
                    Apply
                  </BlackButton>
                </Grid>
              </Grid>
            </div>
          </motion.div>
        )}
      </div>
      {!isLoading && (
        <TableContainer
          component={Paper}
          sx={{ minWidth: 650, maxWidth: "100%", margin: "auto" }}
        >
          <Table
            sx={{ minWidth: 650, maxWidth: "100%" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead style={{ width: "100%" }}>
              <TableRow>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={candidates}
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Candidate" />
                    )}
                    onChange={handleChangeCandidateName}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={interviewers}
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Interviewer" />
                    )}
                    onChange={handleChangeInterviewerName}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={roles}
                    sx={{ width: 130 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Role" />
                    )}
                    onChange={handleChangeRole}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={statuses}
                    sx={{ width: 120 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    onChange={handleChangeStatus}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={employmentTypes}
                    sx={{ width: 180 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Employment Type" />
                    )}
                    onChange={handleChangeEmploymentType}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={templates}
                    sx={{ width: 150 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Template" />
                    )}
                    onChange={handleChangeTemplateName}
                  />
                </TableCell>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={interviewTypes}
                    sx={{ width: 150 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Interview Type" />
                    )}
                    onChange={handleChangeInterviewType}
                  />
                </TableCell>
                <TableCell align="left">
                  <Tooltip
                    title={
                      showDuplicates ? "Hide Duplicates" : "Show Duplicates"
                    }
                  >
                    <Switch
                      checked={showDuplicates}
                      onChange={toggleDuplicates}
                      color="primary"
                    />
                  </Tooltip>
                </TableCell>

                <TableCell align="left">
                  <Tooltip title="Search">
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={setdisplayabledata}
                    >
                      <SearchIcon />
                      &nbsp;Search
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">
                  <Tooltip title="Download Excel">
                    <Button variant="outlined" onClick={()=> convertToExcel(displayingData)}>
                      <DownloadIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showHeader && (
                <TableRow
                  key={-1}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      fontSize: "large",
                    },
                  }}
                >
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Candidate Name&nbsp;
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Interviewer Name
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Evaluated Role
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Interview Date&nbsp;
                    {order === "ASC" && (
                      <Tooltip title="Sort Ascending">
                        <ArrowUpwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("interviewDate")}
                        />
                      </Tooltip>
                    )}
                    {order === "DSC" && (
                      <Tooltip title="Sort Descending">
                        <ArrowDownwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("interviewDate")}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Domain
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Employment Type
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Template
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Interview Type
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Domain Score&nbsp;
                    {order === "ASC" && (
                      <Tooltip title="Sort Ascending">
                        <ArrowUpwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("totalScore")}
                        />
                      </Tooltip>
                    )}
                    {order === "DSC" && (
                      <Tooltip title="Sort Descending">
                        <ArrowDownwardIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => sortingNumber("totalScore")}
                        />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    View
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}

              {displayingData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">
                      <span style={{ marginBottom: "20px" }}>
                        {data.candidateName}
                      </span>
                      <Typography
                        className={{}}
                        variant="body2"
                        component="span"
                      >
                        {data.emailAddress && (
                          <Tooltip
                            title={
                              data.candidateName
                                ? `View ${data.candidateName} Profile`
                                : `View Candidate Profile`
                            }
                          >
                            <OpenInNewIcon
                              className="candidate-profile"
                              onClick={() =>
                                candidateHistory.push(
                                  `/reports/candidate/${base64_encode(
                                    data.emailAddress
                                  )}`
                                )
                              }
                            />
                          </Tooltip>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" style={{}}>
                      {`${caps(data.interviewerName.replace(".", " "))}`}
                    </TableCell>
                    <TableCell align="left">{data.role}</TableCell>
                    <TableCell align="left">
                      {new Date(data.interviewDate).toLocaleDateString(
                        "en-us",
                        {
                          weekday: "long",
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        }
                      )}
                    </TableCell>
                    <TableCell>
                      {data.domains.includes("D2") ? "D2" : "D1"}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color:
                          data.status === "Qualified"
                            ? "green"
                            : data.status === "Reiteration"
                            ? "orange"
                            : "Red",
                      }}
                    >
                      {data.status}
                    </TableCell>
                    <TableCell align="left">{data.employmentType}</TableCell>
                    <TableCell align="left">{data.templateName}</TableCell>
                    <TableCell align="left">{data.interviewType}</TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color:
                          data.status === "Qualified"
                            ? "green"
                            : data.status === "Reiteration"
                            ? "orange"
                            : "Red",
                      }}
                    >
                      {data.totalScore}
                    </TableCell>
                    <TableCell align="left" style={{ cursor: "pointer" }}>
                      <Tooltip title="View File">
                        <RemoveRedEyeIcon
                          onClick={() => {
                            setDisplay(false);
                            setisLoading(true);
                            axios
                              .post(
                                `${proxy}/api/forms`,
                                { id: data.formId },
                                {
                                  withCredentials: true,
                                  headers: {
                                    Accept: "application/json",
                                    Authorization:
                                      user.signInUserSession.idToken.jwtToken,
                                    "Content-Type": "application/json",
                                    "Access-Control-Allow-Credentials": true,
                                  },
                                }
                              )
                              .then((res) => {
                                setisLoading(false);

                                //   console.log(res.data);
                                //   const {section,subSections}=res.data
                                setFormData(res.data.results);
                                setDisplay(true);
                                setisLoading(false);
                              })
                              .catch((error) => {
                                setisLoading(false);
                                // console.log(error);
                              });
                            setStatus(null);
                            setCandidateName(null);
                            setTemplateName(null);
                            setInterviewerName(null);
                            setRole(null);
                            setstatuses([
                              "DisQualified",
                              "Reiteration",
                              "Qualified",
                            ]);
                            setInterviewType(null);
                            setShowHeader(false);
                            setDisplayingData([]);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {displayingData.length === 0 && (
            <div
              style={{ textAlign: "center", fontSize: "24px", width: "100%" }}
            >
              {display ? "See the Form Below" : "No Results Found"}
            </div>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={displayingData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      {isLoading && <Loader />}
      {display && <InterviewerFormView data={formData} />}
    </React.Fragment>
  );
}

export default InterviewerFormSearchCXO;
