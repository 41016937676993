import * as React from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useEffect, useState } from "react";
import axios from "axios";
import "../../styles/CandidateDetails.css";
import BlackButton from "../BlackButton";
import { proxy } from "../../proxy";
import { useHistory } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CreateIcon from "@mui/icons-material/Create";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  CircularProgress,
  Typography,
} from "@mui/material";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import { NotEligible } from "./NotEligiblePage";
import { useSelector } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CandidateDetailsPage(props) {
  const [open, setOpen] = React.useState(true);
  const { user } = useAuthenticator((context) => [context.user]);
  const history = useHistory();
  const [roleData, setRoleData] = useState([]);
  const [role, setRole] = useState("");
  const [templateData, setTemplateData] = useState([]);
  const [recruiterData, setRecruiterData] = useState([]);
  const [templateId, setTemplateId] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [interviewDate, setInterviewDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [userStatus, setUserStatus] = useState(true);
  const [maxDate, setMaxDate] = useState(null);
  const [candidate, setCandidateData] = useState([]);
  const [recruiterName, setRecruiterName] = useState("");
  const [recruiters, setRecruiters] = useState([]);
  const [emailAddress, setEmailAddress] = useState("");
  const [employmentType, setEmploymentType] = useState("");
  const profile= useSelector((state) => {
    return state.profile;
  });
  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];
  const [selectedDomain, setSelectedDomain] = useState("D1");
  const isUserInDomainAccessGroup = user.signInUserSession.idToken.payload['cognito:groups'];
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchError, setFetchError] = useState("");
  const [candidateData, setCandidateFetchData] = useState(null);


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if(ableToSwitch){
    axios
      .get(`${proxy}/api/template_titles/${profile}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setTemplateData(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
    }else{
      axios
      .get(`${proxy}/api/template_titles/${presentProfile}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setTemplateData(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
    }

    axios
      .get(`${proxy}/api/recruiter`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setRecruiterData(res.data.result);
        initializeRecruiterArray(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`${proxy}/api/status/${user.username}`, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setUserStatus(res.data.result);
        // console.log("my status",res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setMaxDate(moment(new Date()).format("YYYY-MM-DD"));
    window.scrollTo(0, 0);
    axios
      .get(`${proxy}/api/role`, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: user.signInUserSession.idToken.jwtToken,

          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setRoleData(res.data.result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function initializeRecruiterArray(data) {
    let recruiters = [];
    if (data !== null && data !== undefined) {
      data.map(({ recruiterUserName }) => {
        recruiters.push(recruiterUserName);
        return 1;
      });
    }
    setRecruiters(recruiters);
    return true;
  }

  const handleRadioChange = (event) => {
    setSelectedDomain(event.target.value);
  };

  const handleChangeEmploymentType = (event) => {
    setEmploymentType(event.target.value);
  }

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };
  const handleChangeCandidateName = (event) => {
    //console.log(event.target.value)
    setCandidateName(event.target.value);
  };

  const handleChangeRecruiterName = (event, newValue) => {
    setRecruiterName(newValue);
  };

  const handleChangeEmailAddress = (event) => {
    //console.log(event.target.value)
    setEmailAddress(event.target.value);
  };
  const handleChangeTemplateId = (event) => {
    setTemplateId(event.target.value);
  };

  const handleChangeInterviewDate = (event) => {
    console.log(event.target.value);
    setInterviewDate(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    candidateName: Yup.string().required("Candidate Name is required"),
    role: Yup.string().required("Role is required"),
    template: Yup.string().required("Template Name is required"),
    emailAddress: Yup.string()
      .email("Email Address must be valid")
      .matches(/^(?!.*@[^,]*,)/)
      .required("Email Address is required"),
    recruiterName: Yup.string().required("Recruiter Name is required"),
    employmentType: Yup.string().required("Employment Type is required")
  
  });

  
  const { register, handleSubmit, setValue, getValues, formState, watch } = useForm({ resolver: yupResolver(validationSchema), mode: "onBlur" });
  const { errors } = formState;
  const includeDomain2 = watch("includeDomain2");

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { candidateName, emailAddress } = getValues();
      if (candidateName && emailAddress) {
        fetchCandidateDetails();
      }
    }, 800);

    return () => clearTimeout(timeout);

    
  }, [watch("candidateName"), watch("emailAddress")]);
  

  const fetchCandidateDetails = async () => {
    setIsLoading(true);
    setFetchError("");
    try {
      const { candidateName, emailAddress } = getValues();
      if (!candidateName || !emailAddress) return;

      const response = await axios.post(`${proxy}/api/fetchCandidateDetails`, {
        candidateName,
        emailAddress
      }, {
        withCredentials: true,
        headers: {
          Authorization: user.signInUserSession.idToken.jwtToken,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
        }
      });

      if(response.status === 200 && response.data.result) {
        console.log("Candidate Data ====>", response.data.result)
        setCandidateFetchData(response.data.result);
        setIsFormDisabled(false);
        setSelectedDomain("D2")
      } else {
        setFetchError("No data found for the entered candidate.");
        setCandidateFetchData(null);
        setIsFormDisabled(false);
        setSelectedDomain("D1")
      }
    } catch (error) {
      setFetchError(error.response?.data?.error || "Error fetching candidate details.");
      setIsFormDisabled(false);
      setSelectedDomain("D1")
    } finally {
      setIsLoading(false);
    }
  }

  function onSubmit(data) {
    console.log("SUCCESS!! :-)\n\n" + JSON.stringify(data, null, 4));

    const payload = {
      candidateName: data.candidateName,
      roleId: data.role,
      templateId: data.template,
      interviewDate: interviewDate,
      emailAddress: data.emailAddress,
      interviewer: user.username,
      interviewerStatus: true,
      profile: ableToSwitch ? profile : presentProfile,
      recruiterName: data.recruiterName,
      interviewType: "Domain",
      employmentType: data.employmentType,
      domains: selectedDomain,
    };

    sendReportsData(payload);

    return false;
    
  }

  const sendReportsData = (data) => {
    axios
      .post(`${proxy}/api/candidatedetails`, data, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          Authorization: user.signInUserSession.idToken.jwtToken,
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
      .then((res) => {
        setCandidateData(res.data.result);
        console.log(res.data.result);
        history.push(`/interviewform/${res.data.result._id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      {!(userStatus == true) && (
        <div>
          <NotEligible></NotEligible>
        </div>
      )}
      {userStatus == true && (
        <div className="candidate-form">
          <p className="candidate-form-header">Enter Candidate Details </p>
          <div className="box">
            <div className="box-secondary">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6}>
                      <div className="candidate-form-option-title">
                        <p>
                          <PeopleIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></PeopleIcon>
                          Candidate Name
                        </p>
                      </div>
                      <TextField
                        label="Candidate Name"
                        value={formState.candidateName}
                        onChange={handleChangeCandidateName}
                        disabled={candidateData}
                        placeholder="Enter Candidate Name"
                        variant="outlined"
                        fullWidth
                        name="candidateName"
                        {...register("candidateName")}
                        error={Boolean(errors.candidateName)}
                        helperText={errors.candidateName?.message}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <div className="candidate-form-option-title">
                        <p>
                          <FileCopyIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></FileCopyIcon>
                          Candidate Email Id
                        </p>
                      </div>
                      <TextField
                        label="Candidate Email Id"
                        value={formState.candidateName}
                        onChange={handleChangeEmailAddress}
                        disabled={candidateData}
                        placeholder="Enter Candidate Email Address"
                        variant="outlined"
                        fullWidth
                        name="emailAddress"
                        {...register("emailAddress")}
                        error={Boolean(errors.emailAddress)}
                        helperText={errors.emailAddress?.message}
                      />
                    </Grid>
                    {isLoading && (
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <CircularProgress />
                      </Grid>
                    )}

                    {/* Display Fetch Error */}
                    {fetchError && (
                      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
                              {fetchError}
                          </Alert>
                      </Snackbar>
                    )}
                    {candidateData && (
                      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
                          <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
                              Domain data fetched properly !!
                          </Alert>
                      </Snackbar>
                    )}
                    <Grid item xs={6}>
                      <div className="candidate-form-option-title">
                        <p>
                          <CalendarTodayIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></CalendarTodayIcon>
                          Interview Scheduled Date
                        </p>
                      </div>
                      <FormControl fullWidth>
                        <input
                          name="Interview Date"
                          type="date"
                          max={maxDate}
                          value={interviewDate}
                          onChange={handleChangeInterviewDate}
                          disabled={isFormDisabled}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="candidate-form-option-title">
                        <p>
                          <PeopleIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></PeopleIcon>
                          Hiring POC
                        </p>
                      </div>
                      <FormControl
                        fullWidth
                        error={Boolean(errors.recruiterName)}
                      >
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={recruiters}
                          fullWidth
                          disabled={isFormDisabled}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Hiring POC"
                              name="recruiterName"
                              {...register("recruiterName")}
                            />
                          )}
                          onChange={handleChangeRecruiterName}
                        />

                        <FormHelperText>
                          {errors.recruiterName?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                      <div className="candidate-form-option-title">
                        <p>
                          <FileCopyIcon
                            style={{ color: "red", marginRight: "5px" }}
                          ></FileCopyIcon>
                          Template
                        </p>
                      </div>
                      <FormControl fullWidth error={Boolean(errors.template)}>
                        <InputLabel id="demo-simple-select-label">
                          Choose Template
                        </InputLabel>
                        <Select
                          name="template"
                          label="Choose Template"
                          value={formState.templateId}
                          onChange={handleChangeTemplateId}
                          disabled={isFormDisabled}
                          {...register("template")}
                        >
                          {templateData.map(
                            ({ _id, templateName, approvalStatus }, index) => {
                              return (
                                approvalStatus && (
                                  <MenuItem key={index} value={_id}>
                                    {templateName}
                                  </MenuItem>
                                )
                              );
                            }
                          )}
                        </Select>
                        <FormHelperText>
                          {errors.template?.message}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                      <Grid item xs={6}>
                        <div className="candidate-form-option-title">
                          <p>
                            <CreateIcon
                              style={{ color: "red", marginRight: "5px" }}
                            ></CreateIcon>
                            Role
                          </p>
                        </div>
                        <FormControl fullWidth error={Boolean(errors.role)}>
                          <InputLabel id="demo-simple-select-label">
                            Choose Role
                          </InputLabel>
                          <Select
                            label="Choose Role"
                            name="role"
                            value={formState.role}
                            onChange={handleChangeRole}
                            disabled={isFormDisabled}
                            {...register("role")}
                          >
                            {roleData.map(({ _id, role }, index) => {
                              return (
                                <MenuItem key={index} value={_id}>
                                  {role}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText>{errors.role?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="candidate-form-option-title">
                          <p>
                            <CreateIcon
                              style={{ color: "red", marginRight: "5px" }}
                            ></CreateIcon>
                            Employment Type
                          </p>
                        </div>
                        <FormControl fullWidth error={Boolean(errors.role)}>
                          <InputLabel id="demo-simple-select-label">
                            Choose Employment Type
                          </InputLabel>
                          <Select
                            label="Choose Employment Type"
                            name="employmentType"
                            value={formState.employmentType}
                            onChange={handleChangeEmploymentType}
                            disabled={isFormDisabled}
                            {...register("employmentType")}
                          >
                              <MenuItem value={"FTE"}>FTE</MenuItem>
                              <MenuItem value={"FXTE"}>FXTE</MenuItem>
                              <MenuItem value={"Contractor"}>Contractor</MenuItem>
                              <MenuItem value={"Spring"}>Spring</MenuItem>
                          </Select>
                          <FormHelperText>{errors.employmentType?.message}</FormHelperText>
                        </FormControl>
                      </Grid>
                      {isUserInDomainAccessGroup.includes("DomainAccess") && (
                      <Grid item xs={6}>
                          <div className="candidate-form-option-title">
                            <p>
                              <AccountCircleIcon
                                style={{ color: "red", marginRight: "5px" }}
                              ></AccountCircleIcon>
                              Domain Round
                            </p>
                          </div>
                          <FormControl>
                            <RadioGroup row value={selectedDomain} onChange={handleRadioChange}>
                              <FormControlLabel
                                value="D1"
                                control={<Radio disabled={isFormDisabled || candidateData?.markedForDomainTwo === true}/>}
                                label="Domain 1"
                              />
                              {isUserInDomainAccessGroup.includes("DomainAccess") && (
                                <FormControlLabel value="D2" control={<Radio disabled={!isFormDisabled && candidateData?.markedForDomainTwo === true} />} label="Domain 2" />
                              )}
                            </RadioGroup>
                            <FormHelperText>{errors.domainType?.message}</FormHelperText>
                          </FormControl>
                      </Grid> 
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <BlackButton disabled={isFormDisabled} fullWidth type="submit">
                      Submit
                    </BlackButton>
                  </Grid>
                </Box>
              </form>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
export default CandidateDetailsPage;
