import { useAuthenticator } from "@aws-amplify/ui-react";
import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@aws-amplify/ui-react";
import axios from "axios";
import { proxy } from "../../proxy";
import { useEffect } from "react";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { motion } from "framer-motion";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import BlackButton from "../BlackButton";
import { tidy, distinct, filter } from "@tidyjs/tidy";
import { Grid, Switch, Tooltip, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TablePagination from "@mui/material/TablePagination";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import "../../styles/InterviewFormSearch.css";
import { useSelector } from "react-redux";


function DomainReportCXO() {
  let date = new Date();
  date.setDate(date.getDate() - 30);

  const { user } = useAuthenticator((context) => [context.user]);
  const profile= useSelector((state) => {
    return state.profile;
  });
  const [candidateName, setCandidateName] = React.useState(null);
  const [emailAddress, setEmailAddress] = React.useState(null);
  const [isLoading, setisLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [displayingData, setDisplayingData] = React.useState([]);
  const [candidates, setCandidates] = React.useState([]);
  const [emailAddresses, setEmailAddresses] = React.useState([]);
  const [display, setDisplay] = React.useState(false);
  const [toDisplay, setToDisplay] = React.useState(false);
  const [showHeader, setShowHeader] = React.useState(false);
  const [startValue, setStartValue] = React.useState(date);
  const [endValue, setEndValue] = React.useState(new Date());
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [getExcelData, setExcelData] = React.useState([]);
  const presentProfile = user.attributes.profile;
  const ableToSwitch = user.attributes['custom:switch'];


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  function initialiseArrays(data) {
    let candidates = [];
    let emailAddresses = [];
    if (data !== null && data !== undefined) {
      data.map(
        ({
          candidateName,
          emailAddress
        }) => {
          candidates.push(candidateName);
          emailAddresses.push(emailAddress);
          return 1;
        }
      );
    }

    setCandidates(tidy(candidates, distinct([])));
    setEmailAddresses(tidy(emailAddresses, distinct([])));
    return true;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getSearchFormsResult();
  }, [profile]);

  useEffect(() => {
    setdisplayabledata();
  }, []);

  const getSearchFormsResult = async () => {
    if(ableToSwitch){
    try {
      setisLoading(true);
      const res = await axios
        .post(
          `${proxy}/api/fetchDomainReport`,
          {
            startDate: startValue,
            endDate: endValue,
            profile: profile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((res) => {
          console.log(res.data.result);
          setData(res.data.result);
          setDisplayingData(res.data.result);
          setisLoading(false);
          initialiseArrays(res.data.result);
          setToDisplay(false);
          setShowHeader(true);
          setDisplay(false);
          setPage(0);
        });
    } catch (error) {
      setisLoading(false);
      console.log("Error getting filtered data.");
    }
  }else{
    try {
      setisLoading(true);
      const res = await axios
        .post(
          `${proxy}/api/fetchDomainReport`,
          {
            startDate: startValue,
            endDate: endValue,
            profile: presentProfile,
          },
          {
            withCredentials: true,
            headers: {
              Authorization: user.signInUserSession.idToken.jwtToken,
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Credentials": true,
            },
          }
        )
        .then((res) => {
          console.log(res.data.result);
          setData(res.data.result);
          setDisplayingData(res.data.result);
          setisLoading(false);
          initialiseArrays(res.data.result);
          setToDisplay(false);
          setShowHeader(true);
          setDisplay(false);
          setPage(0);
        });
    } catch (error) {
      setisLoading(false);
      console.log("Error getting filtered data.");
    }
  }
  };

  
  const getCurrentDateTimeString = () => {
    const currentDate = new Date();
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZone: 'Asia/Kolkata',
    };
    return new Intl.DateTimeFormat('en-IN', options).format(currentDate).replace(/[/:]/g, ''); // Format the date and remove separators
  };

  const convertToExcel = (displayData) => {
    console.log("displayData", displayData)
    try {
      const filteredData = displayData.map(excel =>{
      const candidateDict = {};
      candidateDict['Candidate Name'] = excel['candidateName'] || '';
      candidateDict['Email Address'] = excel['emailAddress'] || '';
      candidateDict['Domain 1 Interview Date'] = excel['interviewDateDomain1'] || '';
      candidateDict['Domain 1 Total Score'] = excel['totalScoreDomain1'] || '';
      candidateDict['Domain 1 Status'] = excel['statusDomain1'] || '';
      candidateDict['Domain 2 Interview Date'] = excel['interviewDateDomain2'] || '';
      candidateDict['Domain 2 Total Score'] = excel['totalScoreDomain2'] || '';
      candidateDict['Domain 2 Status'] = excel['statusDomain2'] || '';
      return candidateDict;
      })
      const ws = XLSX.utils.json_to_sheet(filteredData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const currentDateTimeString = getCurrentDateTimeString();
      const data = new Blob([excelBuffer], { type: "xlsx" });
      FileSaver.saveAs(data, `DomainData_Dump-${currentDateTimeString}.xlsx`);
    } catch (error) {
      alert("Download Status : Error Downloading");
    }
  };

  const handleChangeCandidateName = (event, newvalue) => {
    setCandidateName(newvalue);
  };

  const handleChangeEmailAddress = (event, newvalue) => {
    setEmailAddress(newvalue);
  };

  const setdisplayabledata = () => {
    let datatobedisplayed = data;

    if (
      candidateName !== null &&
      candidateName !== "" &&
      candidateName !== undefined
    ) {
      datatobedisplayed = tidy(
        data,
        filter((d) => d.candidateName === candidateName)
      );
    }

    if (
      emailAddress !== null &&
      emailAddress !== "" &&
      emailAddress !== undefined
    ) {
      datatobedisplayed = tidy(
        data,
        filter((d) => d.emailAddress === emailAddress)
      );
    }

    setShowHeader(true);
    setDisplayingData(datatobedisplayed);
    initialiseArrays(data);
    setDisplay(false);
    setPage(0);
  };
  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "fit-content", margin: "auto" }}>
        <div
          className="sections-header"
          style={{ height: "50px", background: "#fe414d" }}
        >
          <div className="sections-title-header">
            <p style={{ color: "white" }}>Domain Interview Report</p>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          {!toDisplay && (
            <Tooltip title="Open Filters">
              <motion.div
                initial={{ y: -10 }}
                animate={{ y: 0 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowDownIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
          {toDisplay && (
            <Tooltip title="Close Filter">
              <motion.div
                initial={{ y: 0 }}
                animate={{ y: -10 }}
                transition={{
                  duration: 1,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              >
                <KeyboardDoubleArrowUpIcon
                  onClick={() => setToDisplay(!toDisplay)}
                  style={{
                    color: "#2192FF",
                    cursor: "pointer",
                    fontSize: "35px",
                  }}
                />
              </motion.div>
            </Tooltip>
          )}
        </div>

        {toDisplay && (
          <motion.div
            key="box"
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ type: "spring", duration: 2.5 }}
            className="box"
          >
            <div style={{ padding: "5px", width: "100%", marginLeft: "35%" }}>
              <Grid container spacing={1} style={{}}>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      maxDate={new Date()}
                      label="Start Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="startDate"
                          sx={{ width: 150, marginRight: 2 }}
                        />
                      )}
                      value={startValue}
                      onChange={(newValue) => {
                        setStartValue(new Date(newValue).toISOString());
                      }}
                    />
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      minDate={startValue}
                      maxDate={new Date()}
                      label="End Date"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="endDate"
                          sx={{ width: 150 }}
                        />
                      )}
                      value={endValue}
                      onChange={(newValue) => {
                        setEndValue(new Date(newValue).toISOString());
                      }}
                    />
                  </LocalizationProvider>
                  <BlackButton
                    style={{ marginLeft: "6%" }}
                    onClick={getSearchFormsResult}
                  >
                    Apply
                  </BlackButton>
                </Grid>
              </Grid>
            </div>
          </motion.div>
        )}
      </div>
      {!isLoading && (
        <TableContainer
          component={Paper}
          sx={{ minWidth: 650, maxWidth: "100%", margin: "auto", padding: "12px"}}
        >
          <Table
            sx={{ minWidth: 650, maxWidth: "100%" }}
            size="small"
            aria-label="a dense table"
          >
            <TableHead style={{ width: "100%" }}>
              <TableRow>
                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={candidates}
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Candidate" />
                    )}
                    onChange={handleChangeCandidateName}
                  />
                </TableCell>

                <TableCell align="left">
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={emailAddresses}
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Email Address" />
                    )}
                    onChange={handleChangeEmailAddress}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>

                <TableCell align="left">
                  <Tooltip title="Search">
                    <Button
                      variant="outlined"
                      size="medium"
                      onClick={setdisplayabledata}
                    >
                      <SearchIcon />
                      &nbsp;Search
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">
                  <Tooltip title="Download Excel">
                    <Button variant="outlined" onClick={()=> convertToExcel(displayingData)}>
                      <DownloadIcon />
                    </Button>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {showHeader && (
                <TableRow
                  key={-1}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                      fontSize: "large",
                    },
                  }}
                >
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Candidate Name
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Email Address
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Domain 1 Conducted ?
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Interview Date (D1)
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Total Score (D1)
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Status (D1)
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Domain 2 Conducted ?
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Interview Date (D2)
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Total Score (D2)
                  </TableCell>
                  <TableCell align="left" style={{ fontWeight: "bold" }}>
                    Status (D2)
                  </TableCell>
                </TableRow>
              )}

              {displayingData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((data, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0, height: 10 } }}
                  >
                    <TableCell sx={{ padding: "12px" }} align="left">{data.candidateName || "N/A"}</TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">{data.emailAddress || "N/A"}</TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">{data.domainOneConducted ? "Yes" : "No"}</TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">
                      {data.interviewDateDomain1 ? new Date(data.interviewDateDomain1).toLocaleDateString("en-GB") : "N/A"}
                    </TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">{data.totalScoreDomain1 ?? "N/A"}</TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">{data.statusDomain1 || "N/A"}</TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">{data.domainTwoConducted ? "Yes" : "No"}</TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">
                      {data.interviewDateDomain2 ? new Date(data.interviewDateDomain2).toLocaleDateString("en-GB"): "N/A"}
                    </TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">{data.totalScoreDomain2 ?? "N/A"}</TableCell>
                    <TableCell sx={{ padding: "12px" }} align="left">{data.statusDomain2 || "N/A"}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          {displayingData.length === 0 && (
            <div
              style={{ textAlign: "center", fontSize: "24px", width: "100%" }}
            >
              {display ? "See the Form Below" : "No Results Found"}
            </div>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 8, 10]}
            component="div"
            count={displayingData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </React.Fragment>
  );
}

export default DomainReportCXO;
